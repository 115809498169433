const GID = "G-546CJRPF4X";
const dataLayer = (window.dataLayer = window.dataLayer || []);
function gtag() {
  dataLayer.push(arguments);
}
window.gtag = gtag;
gtag("js", new Date());
gtag("config", GID, { send_page_view: false, anonymize_ip: true });

export const onClientEntry = () => {
  gtag("event", "timing_complete", {
    name: "bootstrap",
    value: Math.round(performance.now()),
  });
};

export const onInitialClientRender = () => {
  const gtagScript = document.createElement("script");
  gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${GID}`;
  document.head.appendChild(gtagScript);

  gtag("event", "timing_complete", {
    name: "hydration",
    value: Math.round(performance.now()),
  });
};

export const onRouteUpdate = ({ location }) => {
  gtag("set", "page_path", location.pathname);
  gtag("event", "page_view");
};

export const registerServiceWorker = () => true;
