module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Goossens accountancy","start_url":"/","lang":"nl","background_color":"#00415f","theme_color":"#00415f","display":"minimal-ui","cache_busting_mode":"name","icon":"src/images/icon.svg","include_favicon":false,"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":"c5b673f1fff49a0c2536b47b220b9d7b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
